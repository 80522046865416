<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm
          ref="ajustesOptimizadorRef"
          :fields="this.ajustes_optimizador.fields"
          :card="this.ajustes_optimizador.card"
          @change="ajustesOptimizadorChange"
        >
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card no-body>
          <div class="card-header">
            <div>
              <b-card-title>{{ $t("Ajustes de Variables") }} </b-card-title>
              <b-card-sub-title
                >{{
                  $t(
                    "Selecciona los rangos de valores de cada variable que se usaran en la optimizaciones automáticas"
                  )
                }}
              </b-card-sub-title>
            </div>
          </div>

          <b-card-body style="width: 100%" v-if="!isLoading">

            <b-row style="margin-top: 3rem; margin-bottom: 1rem">
              <b-col>
                <h4>MP16</h4>
              </b-col>
            </b-row>

            <b-row
              v-for="metric in input_variables.filter(
                (metric) =>
                  metric.meta_data.category == 'mp16' &&
                  !['Produto', 'Status'].includes(metric.name)
              )"
              :key="metric.name"
              style="padding-bottom: 1rem"
              class="mb-2"
            >
              <b-col lg="3" xl="3">
                {{ metric.name }}
              </b-col>

              <b-col lg="3" xl="3">
                <vue-slider
                  v-model="metric.meta_data.range"
                  :min="metric.meta_data.minimo"
                  :max="metric.meta_data.maximo"
                  :interval="0.01"
                  :marks="[metric.meta_data.minimo, metric.meta_data.maximo]"
                  direction="ltr"
                  :enable-cross="false"
                  class="mb-2 vue-slider-secondary"
                  style="box-sizing: content-box"
                  @change="ajustesVariablesChange"
                />
              </b-col>

              <b-col lg="1" xl="1">
              </b-col>
              
              <b-col lg="3" xl="3">
                <b-form-select
                  v-model="metric.meta_data.modificable"
                  :options="modificable_options"
                ></b-form-select>
              </b-col>

            </b-row>            


            <b-row style="margin-top: 3rem; margin-bottom: 1rem">
              <b-col>
                <h4>Fibra</h4>
              </b-col>
            </b-row>

            <b-row
              v-for="metric in input_variables.filter(
                (metric) =>
                  metric.meta_data.category == 'fibra' &&
                  !['Produto', 'Status'].includes(metric.name)
              )"
              :key="metric.name"
              style="padding-bottom: 1rem"
              class="mb-2"
            >
              <b-col lg="3" xl="3">
                {{ metric.name }}
              </b-col>

              <b-col lg="3" xl="3">
                <vue-slider
                  v-model="metric.meta_data.range"
                  :min="metric.meta_data.minimo"
                  :max="metric.meta_data.maximo"
                  :interval="0.01"
                  :marks="[metric.meta_data.minimo, metric.meta_data.maximo]"
                  direction="ltr"
                  :enable-cross="false"
                  class="mb-2 vue-slider-secondary"
                  style="box-sizing: content-box"
                  @change="ajustesVariablesChange"
                >
                </vue-slider>
              </b-col>

              <b-col lg="1" xl="1">
              </b-col>
              
              <b-col lg="3" xl="3">
                <b-form-select
                  v-model="metric.meta_data.modificable"
                  :options="modificable_options"
                ></b-form-select>
              </b-col>
            </b-row>

            <hr />

          </b-card-body>

          <b-overlay :show="isLoading" blur="0" opacity=".75" rounded="sm">
            <div style="min-height: 100px"></div>

            <template #overlay>
              <div class="text-center">
                <b-icon
                  icon="hourglass-split"
                  font-scale="2"
                  animation="cylon"
                ></b-icon>
                <p id="cancel-label">{{ $t("Please wait...") }}</p>
              </div>
            </template>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button size="md" @click="saveAjustes" variant="primary">{{
          $t("Guardar Ajustes")
        }}</b-button>
      </b-col>
    </b-row>
  </section>
</template>
        
        <script>


import VueSlider from 'vue-slider-component'
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";


import useApiServices from '@/services/useApiServices.js';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useUtils as useI18nUtils } from '@core/libs/i18n'

import axios from "@axios";
var qs = require('qs');

import
{
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormSelect,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {

  components: {

    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormSelect,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,

    VueSlider,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,


    BOverlay,
    BSpinner,

    BrunaForm,
    ToastificationContent


  },

  methods: {

    findFieldByName(fields, name)
    {

      return fields.find(field => field.id == name)

    },

    ajustesVariablesChange()
    {
      if (!this.isLoading)
      {
        console.log("ajustesVariablesChange");
        this.unsavedChanges = true;

      }

    },

    ajustesOptimizadorChange()
    {
      if (!this.isLoading)
      {
        console.log("ajustesOptimizadorChange");
        this.unsavedChanges = true;

      }

    },



    loadVariables()
    {

      let self = this;

     

      let modelsReadPromise = useApiServices.modelsRead({ name: this.model_name })
      let getLastSettingPromise = useApiServices.getLastSetting()

      return Promise.all([modelsReadPromise, getLastSettingPromise]).then((values) =>
      {


        let response1 = values[0]
        let response2 = values[1]

        if (response2.data != '')
        {
          for (let property in response2.data.meta_data.ajustes_optimizador) 
          {

            let field = this.findFieldByName(self.ajustes_optimizador.fields, property)

            if (field)
            {
              field.value = response2.data.meta_data.ajustes_optimizador[property]
            }

          }

        }



        this.input_variables = response1.data.input_variables.map(variable =>
        {

          if (response2.data != '')
          {
            let variables2 = response2.data.meta_data.ajustes_variables

            variable.meta_data['range'] = variables2[variable.name].range;
            variable.meta_data['value'] = 0;

          }
          else
          {
            let min = variable.meta_data.minimo;
            let max = variable.meta_data.maximo;

            variable.meta_data['range'] = [min, max];
            variable.meta_data['value'] = 0;

          }

          return variable

        })


      });

    
    },


    saveAjustes()
    {


      this.$refs.ajustesOptimizadorRef.validate().then((value) =>
      {

        if (value)
        {

          let ajustes_variables = {}

          this.input_variables.forEach(input_variable =>
          {

            ajustes_variables[input_variable.name] = input_variable.meta_data

          })

          let data = {
            ajustes_optimizador: this.ajustes_optimizador.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
            ajustes_variables: ajustes_variables
          };


          useApiServices.updateLastSetting(data)
            .then((response) =>
            {
              this.unsavedChanges = false;

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t('Carga Exitosa'),
                  icon: "UploadIcon",
                  variant: "success",
                  html: this.$t('Los ajustes se han guardado exitosamente.'),
                },
              });


            })
            .catch((error) =>
            {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t('Ocurrió un Error'),
                  icon: "UploadIcon",
                  variant: "danger",
                  html: this.$t('Ha ocurrido un error al guardar los ajustes</b>.'),
                },
              });

            });


        }
      })



    }






  },


  beforeRouteLeave(to, from, next)
  {




    if (this.unsavedChanges)
    {
      this.$bvModal
        .msgBoxConfirm(this.$t('Tus cambios no se han guardado. ¿Quires descartar los cambios?'), {
          title: this.$t('Confirmar descartar cambios'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('Aceptar'),
          cancelTitle: this.$t('Cancelar'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value =>
        {
          if (value)
          {
            next()
          }
          else
          {
            next(false)
          }

        })

    }
    else
    {
      next()
    }

  },



  mounted()
  {

    let self = this;

    //Se escucha a la llegada de las notificaciones de pusher

    this.isLoading = true;

    this.loadVariables()
    .then((values) => {

      this.isLoading = false;
      this.unsavedChanges = false;

    });





  },




  created()
  {




  },


  data()
  {

    let debug = (this.$route.query.debug == '1')


    return {


      showOverlay: false,
      model_name: "Klabin_Vapor_CE",
      input_variables: [],

      unsavedChanges: false,
      isLoading: true,

      modificable_options: [
        { value: true, text: 'Ativo' },
        { value: false, text: 'Inativo' },
      ],

      ajustes_optimizador: {
        card: {
          title: this.$t("Ajustes del Optimizador"),
          subtitle: this.$t("Selecciona el origen de datos que utilizaras para la planificación"),
          tooltip: "",
          sidebarContent: {
            title: this.$t("Ajustes del Optimizador"),
            body: ' '
          }


        },
        fields: [




          {
            label: this.$t("Costo Vapor/Hora"),
            type: "input",
            id: 'costo_vapor_hora',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 5.825242
          },

          {
            label: this.$t("N° Máximo de Generaciones"),
            type: "select",
            id: 'numero_max_generaciones',
            rules: 'required',
            options: [
              { value: null, text: this.$t("Selecciona una opción") },
              { value: 100, text: this.$t("100 generaciones") },
              { value: 200, text: this.$t("200 generaciones") },
              { value: 300, text: this.$t("300 generaciones") },
              { value: 400, text: this.$t("400 generaciones") },
              { value: 500, text: this.$t("500 generaciones") },
              { value: 1000, text: this.$t("1000 generaciones") },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 300
          },




          {
            label: this.$t("Tipo Función Objetivo"),
            type: "select",
            id: 'tipo_funcion_objetivo',
            rules: 'required',
            options: [
              { value: null, text: this.$t("Selecciona una opción") },
              { value: 'Minimizar Valor (CEdeVaporTotal)', text: this.$t("Minimizar Valor (CEdeVaporTotal)") },
              { value: 'Minimizar Valor (VaporBajaPresion + VaporMediaPresion)', text: this.$t("Minimizar Valor (VaporBajaPresion + VaporMediaPresion)") },
              { value: 'Minimizar Valor (CEdeVaporTotal + VaporBajaPresion + VaporMediaPresion)', text: this.$t("Minimizar Valor (CEdeVaporTotal + VaporBajaPresion + VaporMediaPresion)") },
              { value: 'Minimizar CostoVapor/Hora (CEdeVaporTotal)', text: this.$t("Minimizar CostoVapor/Hora (CEdeVaporTotal)") },
              { value: 'Minimizar CostoVapor/Hora (VaporBajaPresion + VaporMediaPresion)', text: this.$t("Minimizar CostoVapor/Hora (VaporBajaPresion + VaporMediaPresion)") },
              { value: 'Minimizar CostoVapor/Hora (CEdeVaporTotal + VaporBajaPresion + VaporMediaPresion)', text: this.$t("Minimizar CostoVapor/Hora (CEdeVaporTotal + VaporBajaPresion + VaporMediaPresion)") },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },



          {
            label: this.$t("Tipo de Solución"),
            type: "select",
            id: 'tipo_de_solucion',
            rules: 'required',
            options: [
              { value: null, text: this.$t("Selecciona una opción") },
              { value: 'Minimizar Desviación del Escenario Actual', text: this.$t("Minimizar Desviación del Escenario Actual") },
              { value: 'Minimizar Desviación de la Distribución Normal de Variables', text: this.$t("Minimizar Desviación de la Distribución Normal de Variables") },
              { value: 'Ambos', text: this.$t("Ambos") },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },



        ]
      },






    };
  },
};




</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
      
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
      
      <style lang="scss" >
.badge {
  font-size: 85% !important;
}

.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
        